import * as React from 'react';

export enum BUTTON_CORNERS {
  none = 'none',
  sm = 'sm',
  base = 'base',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  full = 'full',
}

export type ButtonCorners = keyof typeof BUTTON_CORNERS;

export enum BUTTON_SIZES {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}
export type ButtonSizes = keyof typeof BUTTON_SIZES;

export enum BUTTON_VARIANTS {
  none = 'none',
  blue400 = 'blue400',
  blue200 = 'blue200',
  red100 = 'red100',
  red400 = 'red400',
  gray500 = 'gray500',
  gray700 = 'gray700',
  gray150 = 'gray150',
  gray50 = 'gray50',
  gray50OnTransparent = 'gray50OnTransparent',
  gray600OnTransparent = 'gray600OnTransparent',
  blueOnTransparent = 'blueOnTransparent',
  blue500OnTransparent = 'blue500OnTransparent',
  blue500OnBlue100 = 'blue500OnBlue100',
  blackOnTransparent = 'blackOnTransparent',
}

export type ButtonVariants = keyof typeof BUTTON_VARIANTS;

export interface ButtonProps {
  type?: string;
  children: React.ReactNode;
  className?: string | Array<string | string[]>;
  typography?: string | Array<string>;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  corners?: ButtonCorners;
  variant?: ButtonVariants;
  size?: ButtonSizes;
  as?: React.ElementType<any>;
}
