import { Middleware, Placement, UseFloatingOptions } from '@floating-ui/react';
import { OffsetOptions } from '@floating-ui/core';

export enum POPPER_THEME {
  light = 'light',
  dark = 'dark',
}

export enum POPPER_TYPE {
  tooltip,
  popover,
}

export interface FloatingUiStateProps {
  delay?: number | Partial<{ open: number; close: number }>;
  duration?: number | Partial<{ open: number; close: number }>;
  hideOnScroll?: boolean;
  initialOpen?: boolean;
  middlewares?: Middleware[];
  offsetSize?: OffsetOptions;
  onOpenChange?: UseFloatingOptions['onOpenChange'];
  placement?: Placement;
  popOnHover?: boolean;
  popperType: POPPER_TYPE;
  ref?: React.RefObject<HTMLElement> | React.ForwardedRef<HTMLElement>;
  showArrow?: boolean;
}

export interface FloatingUiProps extends FloatingUiStateProps {
  render?: (data: { close: () => void }) => React.ReactNode;
  children?: JSX.Element;
  arrowClassName?: string | string[];
  theme?: POPPER_THEME;
  disabled?: boolean;
  className?: string | string[];
}

type PopperTooltipProps = Omit<
  FloatingUiProps,
  'popperType' | 'popOnHover' | 'children' | 'render' | 'middlewares'
>;

export interface TooltipProps extends PopperTooltipProps {
  content?: React.ReactNode;
  children?: JSX.Element;
  tooltipClassName?: string | string[];
}

export type PopoverProps = Omit<FloatingUiProps, 'popperType' | 'as'>;

/// FloatingUI adds these props to child components
export interface FloatingUiChildProps {
  'aria-controls': string;
  'aria-expanded': string;
  'aria-haspopup': string;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onKeyDown: (event: KeyboardEvent) => void;
  onKeyUp: (event: KeyboardEvent) => void;
  onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void;
  onPointerDown: (event: React.MouseEvent<HTMLDivElement>) => void;
}
