import React, { ReactNode } from 'react';

export const BADGE_SIZE = {
  xs: 'xs',
  sm: 'sm',
  base: 'base',
  lg: 'lg',
} as const;
export type BadgeSizes = keyof typeof BADGE_SIZE;

export const BADGE_SHADE = {
  light: 'light',
  base: 'base',
  dark: 'dark',
} as const;
export type BadgeShades = keyof typeof BADGE_SHADE;

export const BADGE_COLOR = {
  gray: 'gray',
  red: 'red',
  yellow: 'yellow',
  green: 'green',
  blue: 'blue',
} as const;
export type BadgeColors = keyof typeof BADGE_COLOR;

export interface BadgeProps {
  children: ReactNode;
  color?: BadgeColors;
  rounded?: boolean;
  size?: BadgeSizes;
  shade?: BadgeShades;
  className?: string | Array<string | string[]>;
  as?: React.ElementType<any>;
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
}
