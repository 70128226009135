import { RowData } from '@tanstack/table-core';
import { flexRender, Row } from '@tanstack/react-table';
import { twMerge } from 'tailwind-merge';
import * as React from 'react';

import { Box } from '../../Box';
import { LoadingBlock } from '../../Loading';

import { useTableContext } from '../useTableContext';
import TableCell from './TableCell';

type IPropsTableRow<TData> = {
  row: Row<TData>;
  className?: string | Array<string | string[]>;
  index: number;
  onRowClick?: ({
    event,
    row,
  }: {
    event: React.MouseEvent<HTMLDivElement>;
    row: TData;
  }) => void;
};

const TableRow = <TData extends RowData>({
  row,
  className,
  index,
  onRowClick,
}: IPropsTableRow<TData>) => {
  const { loading } = useTableContext<TData>();
  const cells = row.getVisibleCells();

  return (
    <Box
      key={index}
      data-mms--datatable-row={index}
      {...(onRowClick && !loading && typeof onRowClick === 'function'
        ? {
            onClick: event => {
              onRowClick({ event, row: row.original });
            },
            role: 'button',
          }
        : {})}
      className={[
        twMerge(
          'odd:bg-white',
          'even:bg-gray-50',
          'w-[max-content]',
          'group',
          'min-w-full',
          'flex',
          'flex-row',
          className,
        ),
      ]}
    >
      {cells.map((cell, cellIndex) => {
        return (
          <TableCell
            className={cell.column.columnDef?.meta?.bodyCell?.classNames || []}
            id={cell.column.id}
            key={cellIndex}
            width={cell.column.getSize()}
          >
            {cell.column.id === 'select' && loading && (
              <Box className={['flex h-full w-fit']}>
                <LoadingBlock className="w-4 h-4 self-center rounded" />
              </Box>
            )}
            {cell.column.id !== 'select' && loading && <LoadingBlock />}
            {!loading &&
              flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        );
      })}
    </Box>
  );
};

export default TableRow;
