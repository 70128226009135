import { FC } from 'react';
import { Box, Button, Text, WistiaVideo } from '@marketmuse/components';

interface CollateralReflectProps {
  onUpgrade?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const CollateralReflect: FC<CollateralReflectProps> = ({ onUpgrade }) => {
  return (
    <Box className={['flex', 'flex-row', 'w-full', 'px-12', 'gap-14']}>
      <Box
        className={[
          'w-1/2',
          'flex',
          'flex-row',
          'items-center',
          'justify-center',
          'py-6',
        ]}
      >
        <Box className={['w-full']}>
          <WistiaVideo videoId={'qvq4j84nfm'} title={'Reflect'} />
        </Box>
      </Box>
      <Box
        className={[
          'w-1/2',
          'flex',
          'flex-col',
          'items-start',
          'justify-center',
        ]}
      >
        <Text className={['prose', 'text-xl', 'font-bold', 'mb-4']} as="h2">
          Remove the guesswork.
        </Text>
        <Text className={['prose', 'text-base', 'mb-4']} as="p">
          Without the right data, you risk chasing topics that aren’t worth your
          time and effort.
        </Text>
        <Text className={['prose', 'text-base', 'mb-4']} as="p">
          Strategy is the only place you can analyze your content coverage while
          doing topic research.
        </Text>
        <Text className={['prose', 'text-base', 'mb-4']} as="p">
          Get personalized data that makes content planning a whole lot easier.
        </Text>
        <Button variant="blue400" onClick={onUpgrade}>
          Schedule Time
        </Button>
      </Box>
    </Box>
  );
};

export default CollateralReflect;
