import { createBrowserRouter, Navigate } from 'react-router-dom';

import withIdpUserOnly from '../hocs/withIdpUserOnly';
import withProviders from '../hocs/withProviders';
import withTailwindCompatibility from '../hocs/withTailwindCompatibility';

import { Root } from './_components/Root';
import { Private } from './_components/Private';
import { Privileged } from './_components/Privileged';
import { NoMatch } from './_components/NoMatch';
import { SiteCatchall } from './_components/SiteCatchall';

import { routes as settingsRoutes } from './settings';
import { routes as publicRoutes } from './public';
import { routes as adminRoutes } from './admin';
import { routes as siteInvRoutes } from './inventory';
import { routes as siteAppRoutes } from './applications';
import {
  orgRoutes as orgDocumentRoutes,
  siteRoutes as siteDocumentsRoutes,
} from './documents';

const RootWrapped = withProviders(Root);

const catchAll = {
  path: '*',
  element: <NoMatch />,
};

export const routes = createBrowserRouter([
  {
    path: '/',
    element: <RootWrapped />,
    children: [
      {
        path: 'guest/*',
        children: [
          {
            path: 'auth/:token',
            lazy: () => import('../containers/Guest/GuestTokenAuth'),
          },
          {
            path: ':routeDomain/:routeDomainValue/:entityType/:entityId/:viewId?/:briefScreen?',
            lazy: () => import('../containers/Guest/GuestTokenReAuth'),
          },
        ],
      },
      {
        path: 'share/*',
        children: [
          {
            path: ':id',
            async lazy() {
              const mod = await import('../containers/Share/ShareAuth');
              return { Component: mod.default };
            },
          },
        ],
      },
      {
        path: 'a',
        element: <Private />,
        children: [
          {
            path: ':slug',
            children: [
              {
                path: ':siteId',
                children: [
                  {
                    path: 'apps',
                    children: siteAppRoutes,
                  },
                  {
                    path: 'inventory',
                    children: siteInvRoutes,
                  },
                  {
                    path: 'documents',
                    children: siteDocumentsRoutes,
                  },
                  {
                    path: 'power-ups',
                    lazy: () =>
                      import('../containers/EmptyStates/PowerUps/index'),
                  },
                  {
                    path: 'start-page',
                    async lazy() {
                      const mod = await import(
                        '@marketmuse/containers/StartPage/StartPage'
                      );
                      return {
                        Component: withTailwindCompatibility({
                          Component: mod.default,
                          style: {
                            height: '100%',
                          },
                        }),
                      };
                    },
                  },
                  {
                    path: 'tags',
                    async lazy() {
                      const mod = await import(
                        '../containers/Tags/TagsTableView'
                      );
                      return {
                        Component: withTailwindCompatibility({
                          Component: mod.default,
                          style: {
                            height: '100%',
                          },
                        }),
                      };
                    },
                  },
                  catchAll,
                ],
              },
              {
                path: 'settings',
                children: settingsRoutes,
              },
              {
                path: 'documents',
                children: orgDocumentRoutes,
              },
              {
                path: '*',
                element: <SiteCatchall />,
              },
            ],
          },
          {
            path: 'admin',
            element: <Privileged />,
            children: adminRoutes,
          },
          {
            ...catchAll,
          },
        ],
      },
      ...publicRoutes,
      {
        path: 'account-setup/:planName?',
        async lazy() {
          const mod = await import('../containers/Account/Setup');
          return { Component: withIdpUserOnly(mod.default) };
        },
      },
      {
        path: '*',
        element: <Navigate to="/login" />,
      },
      {
        path: '/',
        element: <Navigate to="/login" />,
      },
    ],
  },
]);
