import * as React from 'react';
import { RowData } from '@tanstack/react-table';
import { twMerge } from 'tailwind-merge';
import { Box } from '../../Box';
import { useTableContext } from '../useTableContext';
import TableRow from '../components/TableRow';

export interface IPropsBody<TData extends RowData> {
  className?: string | Array<string | string[]>;
  rowClassName?: string | Array<string | string[]>;
  loading?: boolean;
  onRowClick?: ({
    event,
    row,
  }: {
    event: React.MouseEvent<HTMLDivElement>;
    row: TData;
  }) => void;
}

const TableBody = <TData extends RowData>({
  className,
  rowClassName,
  loading,
  onRowClick,
}: IPropsBody<TData>) => {
  const { getRowModel } = useTableContext<TData>();
  const rows = getRowModel().rows;
  const page = null;
  const items = React.useMemo(() => {
    if (loading) {
      return [];
    }

    return page || rows;
  }, [page, rows, loading]);

  return (
    <Box
      className={twMerge(className || ['h-full', 'w-full', 'flex-grow'])}
      data-mms--datatable-body
    >
      {items?.map((row, index) => (
        <TableRow<TData>
          row={row}
          className={rowClassName}
          index={index}
          key={row.id}
          onRowClick={onRowClick}
        />
      ))}
    </Box>
  );
};

export default TableBody;
