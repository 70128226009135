import React, { ReactNode } from 'react';

import { Polymorphic } from '../../Polymorphic';

export type BoxProps<C = HTMLDivElement> = {
  className?: string | Array<string>;
  style?: React.CSSProperties;
  as?: React.ElementType<any>;
  children?: ReactNode;
  onClick?: (event: React.MouseEvent<C>) => void;
};

// https://www.totaltypescript.com/forwardref-with-generic-components
function fixedForwardRef<T, P = unknown>(
  render: (props: P, ref: React.Ref<T>) => React.ReactElement,
): (props: P & React.RefAttributes<T>) => React.ReactElement {
  return React.forwardRef(render) as any;
}

const BoxBase = <C = HTMLDivElement,>(
  base: BoxProps<C>,
  ref: React.ForwardedRef<C>,
) => {
  const { as, className, ...props } = base;
  return (
    <Polymorphic ref={ref} {...props} as={as || 'div'} className={className} />
  );
};

export const Box = fixedForwardRef(BoxBase);

export default Box;
