import { Dialog, Transition } from '@headlessui/react';
import { FC, Fragment, useCallback, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import useModalContentClasses from '../hooks/useModalContentClasses';
import useModalContentTransition from '../hooks/useModalContentTransition';
import { ModalContentProps, MODAL_MODE } from '../types';

const ModalContent: FC<ModalContentProps> = ({
  mode,
  children,
  panelWrapperClassName,
  panelClassName,
  onClose,
}) => {
  const panelRef = useRef<HTMLDivElement>(null);
  const { wrapper, panel } = useModalContentClasses(MODAL_MODE[mode]);
  const transitionProps = useModalContentTransition(MODAL_MODE[mode]);

  const handleOutsideClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <Transition.Child as={Fragment} {...transitionProps}>
      <div
        className={twMerge(wrapper, panelWrapperClassName)}
        onClick={handleOutsideClick}
      >
        <Dialog.Panel ref={panelRef} className={twMerge(panel, panelClassName)}>
          {children}
        </Dialog.Panel>
      </div>
    </Transition.Child>
  );
};

export default ModalContent;
