import React from 'react';
import { twMerge } from 'tailwind-merge';
import { FloatingUi } from '../FloatingUi';
import { POPPER_THEME, POPPER_TYPE, PopoverProps } from '../types';

type PopoverComponent = (props: PopoverProps) => React.ReactElement | null;

export const Popover: PopoverComponent = React.forwardRef<
  HTMLElement,
  PopoverProps
>(
  (
    {
      theme = POPPER_THEME.light,
      showArrow = true,
      render,
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <FloatingUi
        {...props}
        ref={ref}
        popperType={POPPER_TYPE.popover}
        showArrow={showArrow}
        theme={theme}
        render={render}
        className={twMerge([
          'shadow-low',
          'rounded-md',
          theme === POPPER_THEME.light && [
            'bg-white',
            'border',
            'border-gray-200',
            'text-gray-800',
          ],
          theme === POPPER_THEME.dark && ['bg-gray-800', 'text-white'],
          className,
        ])}
      />
    );
  },
);
