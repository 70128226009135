/* eslint-disable-next-line */
import React, { useMemo } from 'react';
import { isArray } from 'lodash';
import { twMerge } from 'tailwind-merge';

import { Polymorphic } from '../../Polymorphic';
import { BadgeProps, BADGE_COLOR, BADGE_SIZE, BADGE_SHADE } from './types';
import {
  getBadgeBgColor,
  getBadgePaddings,
  getBadgeShade,
  getBadgeTextColor,
  getBadgeTextSize,
} from './utils';

export const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(
  (
    {
      as,
      className,
      color = BADGE_COLOR.gray,
      shade = BADGE_SHADE.light,
      rounded,
      size = BADGE_SIZE.base,
      ...props
    },
    ref,
  ) => {
    const element = as || 'span';
    const classNameArray: string[] = isArray(className)
      ? (className as string[])
      : [className];

    const paddingClasses = useMemo(
      () => getBadgePaddings(size, rounded),
      [size, rounded],
    );
    const backgroundShade = useMemo(() => getBadgeShade(shade), [shade]);
    const backgroundColor = useMemo(
      () => getBadgeBgColor(color, backgroundShade),
      [color, backgroundShade],
    );
    const textSize = useMemo(() => getBadgeTextSize(size), [size]);
    const textColor = useMemo(() => getBadgeTextColor(color), [color]);

    return (
      <Polymorphic
        ref={ref}
        {...props}
        as={element}
        className={twMerge(
          [
            ...paddingClasses,
            'inline-flex',
            'items-center',
            rounded ? 'rounded-full' : 'rounded',
            textSize,
            'font-medium',
            backgroundColor,
            textColor,
          ],
          classNameArray,
        )}
      />
    );
  },
);

export default Badge;
